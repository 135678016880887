import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import { PrefectureRomas, prefectures } from '@lifedot/constants/prefectures'
import { initEnvironment } from '@lifedot/relay'
import { fetchQuery } from 'relay-runtime'
import { shopPrefListPageQuery } from '@/queries/shopPrefListPage'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { SearchButtonsWithPanel } from '@/components/shop/SearchButtonsWithPanel'
import { ShopListPageTitle } from '@/components/shop/ShopListPageTitle'
import { shopPrefListPageIndexQuery } from '@/queries/__generated__/shopPrefListPageIndexQuery.graphql'
import { useQuery } from 'relay-hooks'
import { ShopListPageContext } from '@/hooks/shop/use-shop-list-page'
import { ShopList } from '@/components/shop/ShopList'
import { ShopListCallToAction } from '@/components/shop/ShopCallToAction'
import { ShopListFaq } from '@/components/shop/ShopFaq'
import { ShopListSeoHead } from '@/components/shop/ShopSeo/ShopListSeoHead'
import { getSearchParamsByQuery } from '@/hooks/shop/use-shop-list-page/dependencies'
import { useRouter } from 'next/router'
import { ShopListBreadcrumb } from '@/components/shop/ShopBreadcrumb'
import { ShopPaginationStyled } from '@/components/shop/ShopPagination'
import { ShopLinks } from '@/components/shop/ShopLinks'
import { useEventTracking } from '@lifedot/tracking'
import { NotFound } from '@lifedot/components/NotFound'
import { SingleColumn } from '@lifedot/layout'

export const getStaticPaths: GetStaticPaths = async () => {
  if (
    !['production', 'preview'].includes(process.env.NEXT_PUBLIC_BUILD_ENV ?? '')
  )
    return {
      paths: [],
      fallback: 'blocking'
    }

  const paths = Object.keys(prefectures).map((pref) => ({
    params: { pref }
  }))
  return {
    paths: paths,
    fallback: false
  }
}

export const getStaticProps: GetStaticProps<
  ListPageProps,
  {
    pref: string
  }
> = async ({ params }) => {
  const prefectureRoma = params?.pref ?? ''

  try {
    const { environment, relaySSR }: ReturnType<typeof initEnvironment> =
      initEnvironment()
    await fetchQuery<shopPrefListPageIndexQuery>(
      environment,
      shopPrefListPageQuery,
      {
        prefectureRoma
      }
    ).toPromise()
    const [relayData] = await relaySSR.getCache()

    const [cacheString, payload] = relayData
    if (!payload.data)
      return {
        notFound: true
      }

    return {
      props: {
        prefecture: {
          roma: prefectureRoma,
          name: prefectures[prefectureRoma as PrefectureRomas]
        },
        relayData:
          relayData && 'json' in payload ? [[cacheString, payload.json]] : null
      },
      revalidate: Number(process.env.DEFAULT_REVALIDATE_TERM)
    }
  } catch (e) {
    if (typeof e === 'object' && e !== null && 'res' in e) {
      if (typeof (e as { res?: unknown }).res === 'object')
        console.log((e as { res?: { errors?: unknown } }).res?.errors)
    } else if (e instanceof Error) console.log(e.message)

    // ページの生成自体は成功したことにしておき、次のアクセスで再生成し直す
    return {
      props: {
        prefecture: {
          roma: prefectureRoma,
          name: prefectures[prefectureRoma as PrefectureRomas]
        }
      },
      revalidate: 1
    }
  }
}

type ListPageProps = {
  prefecture: {
    roma: string
    name: string
  }
}

const Page: NextPage<ListPageProps> = ({ prefecture }) => {
  const { query } = useRouter()
  const { cityIds, from } = getSearchParamsByQuery(query)
  const { data } = useQuery<shopPrefListPageIndexQuery>(shopPrefListPageQuery, {
    prefectureRoma: prefecture.roma,
    cityIds,
    from
  })
  useEventTracking({ category: 'SHOP_LIST' })
  if (!data?.cities) return null
  if (!data?.shops.total) return <NotFound />

  return (
    <SingleColumn>
      <ShopListPageContext.Provider
        value={{
          prefecture,
          city: null,
          pagination: data.shops.pagination,
          total: data.shops.total,
          searchParams: { cityIds, sort: { field: 'default', order: 'asc' } }
        }}
      >
        <ShopListSeoHead />
        <ShopListBreadcrumb />
        <AddMarginWrapper spacing={6}>
          <ShopListPageTitle />
          <SearchButtonsWithPanel items={data.cities} />
          <ShopList shops={data.shops} />
          <AddMarginWrapper spacing={8}>
            <ShopPaginationStyled />
            <ShopListCallToAction />
            <ShopListFaq />
          </AddMarginWrapper>
          <ShopLinks fragmentRef={data} prefecture={prefecture} />
        </AddMarginWrapper>
      </ShopListPageContext.Provider>
    </SingleColumn>
  )
}

export default Page
