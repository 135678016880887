import { graphql } from 'relay-runtime'

export const shopPrefListPageQuery = graphql`
  query shopPrefListPageIndexQuery(
    $prefectureRoma: String!
    $cityIds: [String]
    $cityId: Int
    $from: Int = 0
  ) {
    cities: listCitiesWithShopCountByPrefectureRoma(
      prefectureRoma: $prefectureRoma
      limit: 999
    ) {
      ...SearchButtonsWithPanel_Fragment
    }
    shops: listShops(
      filter: { prefectures: [$prefectureRoma], cities: $cityIds }
      page: { from: $from, size: 20 }
    ) {
      total
      pagination {
        currentPageNumber
        to
        perPageSize
        lastPageNumber
        hasPrev
        hasNext
        from
      }
      ...ShopList_Fragment
    }
    ...ShopLinks_Fragment
      @arguments(prefectureRoma: $prefectureRoma, cityId: $cityId)
  }
`
